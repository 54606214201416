
@if (modalData$ | async; as modalData) {

  <app-kurz-modal-wrapper [options]="options">

    <ng-template modalSlot="aside">

      <app-button
        [label]=" 'common.cancel' | cxTranslate "
        buttonSize="SMALL"
        (activateElement)="closeModal()"
        colorTheme="SECONDARY"
      ></app-button>

      <app-button
        [label]=" 'common.send' | cxTranslate "
        buttonSize="SMALL"
        (activateElement)="send()"
        [disabled]="this.messageText.length <= 0 || ((isUserAnonymous$ | async) && (isUserAnonymouInputInvalid$ | async))"
        colorTheme="PRIMARY"
      ></app-button>

    </ng-template>

    <!-- CONTEXT START -->
    <ng-template modalSlot="kurzContext">
      <div class="contact-request-row">

        <ng-container *ngFor="let item of modalData.contextItems">
          <app-input
            [label]=" item.labelTranslationKey ? (item.labelTranslationKey | cxTranslate) : item.label"
            [value]="item.value"
            textonly
            narrow
          ></app-input>
        </ng-container>

      </div>
    </ng-template>
    <!-- CONTEXT END -->

    <ng-template modalSlot="content">

      @if (sent$ | async) {
        <div class="contact-sent-column">

          <div class="contact-sent-icon"><app-icon [iconType]="iconType" [colorTheme]="colorTheme"></app-icon></div>
          <div class="contact-sent-text-1">{{ sentMsgTranslationKeys[0] | cxTranslate }}</div>
          <div class="contact-sent-text-2">{{ sentMsgTranslationKeys[1] | cxTranslate }}</div>

          <ng-template #buttonIfFailed>

            <div class="contact-sent-button">
              <div>
                <app-button
                  [label]=" 'common.back' | cxTranslate "
                  buttonSize="SMALL"
                  (activateElement)="back()"
                  colorTheme="PRIMARY"
                ></app-button>
              </div>
            </div>

          </ng-template>

          <ng-container *ngIf="(sentSuccessfully$ | async); else buttonIfFailed">
            <div class="contact-sent-button">
              <app-button
                [label]=" 'common.ok' | cxTranslate "
                buttonSize="SMALL"
                (activateElement)="closeModal()"
                colorTheme="PRIMARY"
              ></app-button>
            </div>
          </ng-container>

        </div>
      } @else {

        @if (isUserAnonymous$ | async) {
          <div class="anonymous-user-data">

            <app-input
              class="name"
              [label]="'contactModal.nameLabel' | cxTranslate"
              [placeholder]="'contactModal.nameLabel' | cxTranslate"
              [(value)]="name"
              name="name"
              appInputRequiredValidator
              [formGroupName]="formGroupName"
            ></app-input>

            <app-input
              class="email"
              [label]="'contactModal.emailLabel' | cxTranslate"
              [placeholder]="'contactModal.emailLabel' | cxTranslate"
              [(value)]="email"
              name="email"
              appInputRequiredValidator
              appInputEmailValidator
              [formGroupName]="formGroupName"
            ></app-input>

            <app-input
              class="name"
              [label]="'contactModal.companyNameLabel' | cxTranslate"
              [placeholder]="'contactModal.companyNameLabel' | cxTranslate"
              [(value)]="companyName"
              name="companyName"
              appInputRequiredValidator
              [formGroupName]="formGroupName"
            ></app-input>

            <app-kurz-country-autocomplete
              (select)="userSelectsCountryMailAddress($event)"
              [formGroupName]="formGroupName"
              required
            ></app-kurz-country-autocomplete>

          </div>
        }

        <app-textarea
          class="contact-request-text"
          [label]=" modalData.textareaLabelTranslationKey ? (modalData.textareaLabelTranslationKey | cxTranslate) : modalData.textareaLabel"
          [placeholder]="modalData.textareaPlaceholderTranslationKey ? (modalData.textareaPlaceholderTranslationKey | cxTranslate) : modalData.textareaPlaceholder"
          [(value)]="messageText"
        ></app-textarea>
      }

    </ng-template>

  </app-kurz-modal-wrapper>

}
