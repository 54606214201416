import { Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { AutocompleteDataSource } from '@util/components/autocomplete/autocomplete-data-source';
import { coerceBoolean } from '@util/functions/objects';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { CountryMailAddress, KurzMailService } from 'src/app/custom/services/kurz-mail.service';

@Component({
  selector: 'app-kurz-country-autocomplete',
  templateUrl: './kurz-country-autocomplete.component.html',
  styleUrl: './kurz-country-autocomplete.component.scss'
})
export class KurzCountryAutocompleteComponent implements OnDestroy {

  private kurzMailService = inject(KurzMailService);

  private countryMailAddresses$ = this.kurzMailService.getCountryMailAddresses();

  private _requiredBehaviorSubject = new BehaviorSubject<boolean>(false);
  protected _formGroupNameBehaviorSubject = new BehaviorSubject<string>('');

  private dataSub: Subscription;
  private selectSub: Subscription;

  countryMailAddressesAutocompleteDataSource$ = new BehaviorSubject<AutocompleteDataSource<CountryMailAddress>>(null);

  @Input()
  label: string;

  @Output()
  readonly select = new EventEmitter<CountryMailAddress>();

  get required(): boolean {
    return this._requiredBehaviorSubject.value;
  }

  @Input()
  set required(value: boolean) {
    this._requiredBehaviorSubject.next(coerceBoolean(value));
  }

  get formGroupName(): string {
    return this._formGroupNameBehaviorSubject.value;
  }

  @Input()
  set formGroupName(value: string) {
    this._formGroupNameBehaviorSubject.next(value);
  }

  constructor() {
    this.dataSub = this.countryMailAddresses$.subscribe(data => {

      const options: ConstructorParameters<typeof AutocompleteDataSource<CountryMailAddress>>[0] = {
        fallbackLabelTranslationKey: 'contactModal.countryDropdownLabel',
        noVisibleItemsTranslationKey: 'contactModal.noCountryFound',
        strict: true,
        maxVisibleItems: 4,
        filterItems: true,
        caseInsensitiveMatching: true
      };

      const items: ConstructorParameters<typeof AutocompleteDataSource<CountryMailAddress>>[1] = data.map(item => {
        return {
          label: item.name,
          value: item.code,
          injectedRenderingData: item
        };
      });

      this.countryMailAddressesAutocompleteDataSource$.next(new AutocompleteDataSource(options, items));

      this.selectSub?.unsubscribe();

      this.selectSub = this.countryMailAddressesAutocompleteDataSource$.value.selectedItem$.pipe(
        filter(data => !!data),
      ).subscribe(item => this.select.emit(item.injectedRenderingData));

    });
  }

  ngOnDestroy(): void {
    this.dataSub?.unsubscribe();
    this.selectSub?.unsubscribe();

    if (this.countryMailAddressesAutocompleteDataSource$.value) {
      this.countryMailAddressesAutocompleteDataSource$.value.complete();
    }
  }

}
