import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {ColorTheme} from '@util/components/shared/color-theme/theme.enum';
import {StandardizedModalComponentOptions} from '@util/components/util-modal/standardized-modal/standardized-modal.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {CountryMailAddress, KurzMailOptionObject, KurzMailService, MailType} from 'src/app/custom/services/kurz-mail.service';
import {KurzIconType} from '../../../custom-configuration-modules/custom-icon-types-mapping.module';
import {KurzModalWrapperComponent} from '../kurz-modal-wrapper/kurz-modal-wrapper.component';
import {BaseModalComponent} from '@util/services/util-modal-types';
import {AuthService} from '@spartacus/core';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {UtilOmnipresentFormGroupService} from '@util/services/util-omnipresent-form-group.service';

export type KurzContactMailObject = Omit<KurzMailOptionObject, 'customerInput'>;

export interface KurzContactRequestItem {
  type: 'textonlyInput';
  label?: string;
  labelTranslationKey?: string;
  /**
   * the value of the item in the context area of the modal
   */
  value?: string;
}


export interface KurzContactRequestModalData {

  mailType: MailType;
  /**
   * required if mailType === 'PRICE'
   */
  productId?: string;
  /**
   * required if mailType === 'ORDER_DETAIL'
   */
  orderId?: string;
  /**
   * required if mailType === 'CONTRACT'
   */
  contractId?: string;
  /**
   * items, which will be displayed in the context area of the modal
   */
  contextItems: KurzContactRequestItem[];
  /**
   * required if mailType === 'REQUEST_ACCESS' or anonymous user 'PRODUCT_SAMPLE' or 'PRODUCT_REQUEST'
   */
  name?: string;
  email?: string;
  companyName?: string;
  /**
   * the header for the modal
   */
  header?: string;
  /**
   * the translation key of the translation, which will be used as the header for the modal
   */
  headerTranslationKey?: string;
  textareaLabel?: string;
  textareaLabelTranslationKey?: string;
  textareaPlaceholder?: string;
  textareaPlaceholderTranslationKey?: string;
}

@Component({
  selector: 'app-kurz-contact-request-modal',
  templateUrl: './kurz-contact-request-modal.component.html',
  styleUrls: ['./kurz-contact-request-modal.component.scss']
})
export class KurzContactRequestModalComponent extends BaseModalComponent<KurzContactRequestModalData, void> implements OnInit {

  @ViewChild(KurzModalWrapperComponent)
  kurzModalWrapper: KurzModalWrapperComponent;

  private utilOmnipresentFormGroupService = inject(UtilOmnipresentFormGroupService);
  private kurzMailService = inject(KurzMailService);
  private authService = inject(AuthService);

  private _modalDataBehaviorSubject = new BehaviorSubject<KurzContactRequestModalData>(null);
  private sentBehaviorSubject = new BehaviorSubject<boolean>(false);
  private sentSuccessfullyBehaviorSubject = new BehaviorSubject<boolean>(true);

  get modalData$(): Observable<KurzContactRequestModalData> {
    return this._modalDataBehaviorSubject.asObservable();
  }

  /**
   * there are two states in this modal "request has not yet been sent" (sent$ will result in false)
   * and "request has been sent" (sent$ will result in true)
   * Note: TODO the view and respective logic has not been implemented yet
   */
  get sent$(): Observable<boolean> {
    return this.sentBehaviorSubject.asObservable();
  }

  get sentSuccessfully$(): Observable<boolean> {
    return this.sentSuccessfullyBehaviorSubject.asObservable();
  }

  isUserAnonymous$ = this.authService.isUserLoggedIn().pipe(
    map(loggedIn => !loggedIn),
    distinctUntilChanged()
  );

  isUserAnonymouInputInvalid$ = this.utilOmnipresentFormGroupService.getFormGroupError$(this.formGroupName).pipe(map(errors => {
    return errors.invalid;
  }));

  options: StandardizedModalComponentOptions;

  messageText: string = '';
  name: string = '';
  email: string = '';
  companyName: string = '';
  countryMailAddress: CountryMailAddress;
  iconType = KurzIconType.THUMBS_UP;
  colorTheme = ColorTheme.SUCCESS;
  sentMsgTranslationKeys: string[] = [];

  get formGroupName(): string {
    return 'anonymousUserInput';
  }

  ngOnInit(): void {
    this.extractModalDataFromInjectedData();
  }

  private extractModalDataFromInjectedData(data?: KurzContactRequestModalData) {

    data = data || this.injectedModalData;

    this.options = {
      ref: this,
      header: data?.header,
      headerTranslationKey: data?.headerTranslationKey,
    };

    if (!this.options.header && !this.options.headerTranslationKey) {
      this.options.headerTranslationKey = 'contactModal.defaultHeader';
    }

    if (!data.textareaLabel && !data.textareaLabelTranslationKey) {
      data.textareaLabelTranslationKey = 'contactModal.textLabel';
    }

    if (!data.textareaPlaceholder && !data.textareaPlaceholderTranslationKey) {
      data.textareaPlaceholder = '...';
    }

    this._modalDataBehaviorSubject.next(data);
  }

  send() {

    const data = this._modalDataBehaviorSubject.value;

    const obj: KurzMailOptionObject = {
      customerInput: this.messageText,
      mailType: data.mailType,
      productId: data.productId,
      contractId: data.contractId,
      orderId: data.orderId
    };

    this.isUserAnonymous$.pipe(take(1)).subscribe(isAnonymous => {
      if (isAnonymous) {
        obj.name = this.name;
        obj.email = this.email;
        obj.companyName = this.companyName;
        obj.countryMail = this.countryMailAddress?.code;
      }
    });

    this.kurzMailService.sendMail(obj).subscribe((res) => {

      this.sentMsgTranslationKeys = [];

      const data = Object.assign({}, this.injectedModalData);
      data.header = '';

      if (res?.response === 'success') {
        this.iconType = KurzIconType.VERIFIED;
        this.colorTheme = ColorTheme.SUCCESS;
        this.sentMsgTranslationKeys.push('contactModal.sentSuccessfully_1', 'contactModal.sentSuccessfully_2');
        data.headerTranslationKey = 'contactModal.headerSuccessful';
        this.sentSuccessfullyBehaviorSubject.next(true);
      } else {
        this.iconType = KurzIconType.ERROR;
        this.colorTheme = ColorTheme.DANGER;
        this.sentMsgTranslationKeys.push('contactModal.sentFailed_1', 'contactModal.sentFailed_2');
        data.headerTranslationKey = 'contactModal.headerFailed';
        this.sentSuccessfullyBehaviorSubject.next(false);
      }

      this.extractModalDataFromInjectedData(data);

      this.sentBehaviorSubject.next(true);
      this.kurzModalWrapper?.isKurzContextVisible$.next(!this.sentBehaviorSubject.value);
      this.kurzModalWrapper?.isCustomAsideVisible$.next(!this.sentBehaviorSubject.value);

    });

  }

  back() {
    this.extractModalDataFromInjectedData();
    this.sentBehaviorSubject.next(false);
    this.kurzModalWrapper?.isKurzContextVisible$?.next(!this.sentBehaviorSubject.value);
    this.kurzModalWrapper?.isCustomAsideVisible$.next(!this.sentBehaviorSubject.value);
  }

  userSelectsCountryMailAddress(item: CountryMailAddress) {
    this.countryMailAddress = item;
  }

}
